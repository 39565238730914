import React from "react";

const Header = () => {
  return (
    <div className="header-section">
      <h1 className="title">use-keyboard-shortcut</h1>
      <br />
      <a href="https://badge.fury.io/js/use-keyboard-shortcut">
        <img
          src="https://badge.fury.io/js/use-keyboard-shortcut.svg"
          alt="npm version"
          height="18"
        />
      </a>
    </div>
  );
};

export default Header;
