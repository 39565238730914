import React from "react";

import CodeSample from "../CodeSample";
import Header from "../Header";
import Override from "../OverrideSystemDoc";
import IgnoreInputFields from "../IgnoreInputFieldsDoc";
import RepeatOnHold from "../RepeatOnHoldDoc";
import MadeBy from "../MadeBy";

function App() {
  return (
    <div className="main">
      <Header />
      <h2 className="heading">Code Sample:</h2>
      <br />
      <CodeSample />
      <br />
      <h2 className="heading">Return Object:</h2>
      <br />
      <span>
        <b>flushHeldKeys</b>{" "}
        <p className="inline text-opac">
          -- Calling this function will reset the hook's internal ref managing
          the held keys state.
        </p>
      </span>
      <br />
      <br />
      <h2 className="heading">Options Object:</h2>
      <br />
      <Override />
      <br />
      <br />
      <IgnoreInputFields />
      <br />
      <br />
      <RepeatOnHold />
      <MadeBy />
    </div>
  );
}

export default App;
