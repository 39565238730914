/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

import OrangeBold from "../formatting/OrangeBold";

const RepeatOnHold = () => {
  const [underline, setUnderline] = useState(false);

  const keysSystem = ["Shift", "E"];

  const callback = useCallback(
    (_, b, c) => {
      console.group("Override");
      console.log(
        `${keysSystem.join(
          " + "
        )} - Preventing system save dialog from opening and ${
          underline ? "" : "un"
        }bolding text`
      );
      console.log("heldKeys", b);
      console.log("newHeldKeys", c);
      console.groupEnd();
      setUnderline((b) => !b);
    },
    [setUnderline]
  );

  useKeyboardShortcut(keysSystem, callback, {
    overrideSystem: true,
    repeatOnHold: true
  });

  return (
    <div className="docs-section">
      <span>
        <b>repeatOnHold</b>{" "}
        <p className="inline text-opac">
          -- default: true -- Calling the callback function repeatedly as long as the
          shortcut is held enable by setting this to
        </p>
        <OrangeBold> true</OrangeBold>{" "}
        <p className="inline text-opac">in the options object. </p>
      </span>
      <br />
      <br />
      <p>
        Press and hold any of the above shortcuts to test the repeat on hold functionlity.
      </p>
    </div>
  );
};

export default RepeatOnHold;
