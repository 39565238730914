/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const MadeBy = () => {
  return (
    <footer>
      created by{" "}
      <a target="_blank" href="https://tyukayev.com">
        arthur tyukayev
      </a>
    </footer>
  );
};

export default MadeBy;
