import React from "react";

const __html = `
<!-- HTML generated using hilite.me -->
<div style="background: #111111; overflow:auto;width:auto;border:solid gray;border-width:.1em .1em .1em .8em;padding:.2em .6em;font-family: JetBrains Mono, monospace; "><pre style="margin: 0; line-height: 150%"><span style="color: #fb660a; font-weight: bold">import</span> <span style="color: #ffffff">React</span> <span style="color: #ffffff">from</span> <span style="color: #0086d2">&quot;react&quot;</span><span style="color: #ffffff">;</span>
<span style="color: #fb660a; font-weight: bold">import</span> <span style="color: #ffffff">useKeyboardShortcut</span> <span style="color: #ffffff">from</span> <span style="color: #0086d2">&quot;use-keyboard-shortcut&quot;</span><span style="color: #ffffff">;</span>

<span style="color: #fb660a; font-weight: bold">const</span> <span style="color: #ffffff">App</span> <span style="color: #ffffff">=</span> <span style="color: #ffffff">()</span> <span style="color: #ffffff">=&gt;</span> <span style="color: #ffffff">{</span>
  <span style="color: #fb660a; font-weight: bold">const</span> <span style="color: #ffffff">{</span> <span style="color: #ffffff">flushHeldKeys</span> <span style="color: #ffffff">}</span> <span style="color: #ffffff">=</span> <span style="color: #ffffff">useKeyboardShortcut(</span>
    <span style="color: #ffffff">[</span><span style="color: #0086d2">&quot;Shift&quot;</span><span style="color: #ffffff">,</span> <span style="color: #0086d2">&quot;H&quot;</span><span style="color: #ffffff">],</span>
    <span style="color: #ffffff">(shortcutKeys)</span> <span style="color: #ffffff">=&gt;</span> <span style="color: #ffffff">console.log(</span><span style="color: #0086d2">&quot;Shift + H has been pressed.&quot;</span><span style="color: #ffffff">),</span>
    <span style="color: #ffffff">{</span> 
      <span style="color: #ffffff">overrideSystem:</span> <span style="color: #fb660a; font-weight: bold">false</span><span style="color: #ffffff">,</span>
      <span style="color: #ffffff">ignoreInputFields:</span> <span style="color: #fb660a; font-weight: bold">false</span><span style="color: #ffffff">,</span> 
      <span style="color: #ffffff">repeatOnHold:</span> <span style="color: #fb660a; font-weight: bold">false</span> 
    <span style="color: #ffffff">}</span>
  <span style="color: #ffffff">);</span>

  <span style="color: #fb660a; font-weight: bold">return</span> <span style="color: #ffffff">&lt;div&gt;Hello</span> <span style="color: #ffffff">World&lt;/div&gt;;</span>
<span style="color: #ffffff">};</span>
</pre></div>

`;

const CodeSample = () => {
  return (
    <div className="code-section">
      <div dangerouslySetInnerHTML={{ __html }} />
    </div>
  );
};

export default CodeSample;
