/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

import OrangeBold from "../formatting/OrangeBold";

const Override = () => {
  const isMac = /Mac OS X/.test(navigator.userAgent);

  const [underline, setUnderline] = useState(false);

  const keysSystem = isMac ? ["Meta", "F"] : ["Control", "F"];
  const keysSystemAlternate = isMac ? ["Meta", "S"] : ["Control", "S"];
  const keysSystemDisplay = isMac ? ["Command", "F"] : ["Control", "F"];
  const keysSystemAlternateDisplay = isMac ? ["Command", "S"] : ["Control", "S"];

  const callback = useCallback(
    (_, b, c) => {
      console.group("Override");
      console.log(
        `${keysSystem.join(
          " + "
        )} - Preventing system save dialog from opening and ${
          underline ? "" : "un"
        }bolding text`
      );
      console.log("heldKeys", b);
      console.log("newHeldKeys", c);
      console.groupEnd();
      setUnderline((b) => !b);
    },
    [setUnderline]
  );

  useKeyboardShortcut(keysSystem, callback, {
    overrideSystem: true,
    repeatOnHold: true
  });
  useKeyboardShortcut(keysSystemAlternate, callback, {
    overrideSystem: true,
    repeatOnHold: true
  });

  return (
    <div className="docs-section">
      <span>
        <b>overrideSystem</b>{" "}
        <p className="inline text-opac">
          -- default: false -- Overriding the system shortcut is done by setting
          this value to
        </p>
        <OrangeBold> true</OrangeBold>{" "}
        <p className="inline text-opac">in the options object. </p>
      </span>
      <br />
      <br />
      <span className="text-opac">
        * Keep in mind, that this is subject to the browser's whim, there are
        many browsers that simply don't allow some shortcuts to be overridden.
        For example, regardless of what you set here you will not prevent open
        new tab keyboard shortcuts or close tab keyboard shortcuts.
      </span>
      <br />
      <br />
      <p>
        Press <OrangeBold>`{keysSystemDisplay.join(" + ")}`</OrangeBold> or{" "}
        <OrangeBold>`{keysSystemAlternateDisplay.join(" + ")}`</OrangeBold> to
        test a system override.{" "}
        <p className={`inline ${underline && "underline"}`}>
          It should not open the save or find dialog, instead this text should
          underline.
        </p>
      </p>
    </div>
  );
};

export default Override;
