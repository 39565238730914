/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import useKeyboardShortcut from "use-keyboard-shortcut";

import OrangeBold from "../formatting/OrangeBold";

const IgnoreInputFields = () => {
  const isMac = /Mac OS X/.test(navigator.userAgent);

  const [inputValue, setInputValue] = useState("");

  const keysSystem = isMac ? ["Meta", "A"] : ["Control", "A"];
  const keysSystemDisplay = isMac ? ["Command", "A"] : ["Ctrl", "A"];

  const callback = useCallback((_, b, c) => {
    console.group("IgnoreInputFields");
    console.log(`${keysSystem.join(" + ")} - Opening alert dialog`);
    console.log("heldKeys", b);
    console.log("newHeldKeys", c);
    console.groupEnd();

    // alert("You should only see this alert when not focused in the input.");
    setInputValue(Math.random().toString().replace("0.", ""));
  }, []);

  useKeyboardShortcut(keysSystem, callback, {
    ignoreInputFields: true,
    overrideSystem: true,
    repeatOnHold: false,
  });

  const handleFocus = useCallback(() => {
    setInputValue("Use shortcut");
  }, [setInputValue]);

  const handleBlur = useCallback(() => {
    setInputValue("");
  }, [setInputValue]);

  return (
    <div className="docs-section">
      <span>
        <b>ignoreInputFields</b>{" "}
        <p className="inline text-opac">
          -- default: true -- Preventing the shortcut from being called inside
          of inputs is done by setting this value to
        </p>
        <OrangeBold> true</OrangeBold>
        <p className="inline text-opac"> in the options object.</p>
      </span>
      <br />
      <br />
      <input
        placeholder="Focus here"
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <br />
      <br />
      <span>
        Focus the input above then press{" "}
        <OrangeBold>`{keysSystemDisplay.join(" + ")}`</OrangeBold> to test this
        functionality. It should randomly generate a number in the input above
        unless you're focused on the input.
      </span>
    </div>
  );
};

export default IgnoreInputFields;
